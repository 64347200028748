/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-extraneous-dependencies */
import styled, { keyframes } from "styled-components";

// Define a keyframe animation
const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Loader = styled.div`
  width: 10rem;
  height: 10rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(https://raraityart55525-prod.s3.us-west-2.amazonaws.com/public/star.gif)
    center no-repeat;
  background-size: contain;
  animation: ${spin} 2s linear infinite;
`;

export const Loader2 = styled.div`
  width: 5rem;
  height: 5rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(https://raraityart55525-prod.s3.us-west-2.amazonaws.com/public/star.gif)
    center no-repeat;
  background-size: contain;
  animation: ${spin} 2s linear infinite;
`;

export const ButtonLoader = styled.div`
  width: 1rem;
  height: 1rem;
  position: absolute;
  justify-content: center;
  align-items: center;
  background: url(https://raraityart55525-prod.s3.us-west-2.amazonaws.com/public/star.gif)
    center no-repeat;
  background-size: contain;
  animation: ${spin} 2s linear infinite;
`;
